const config = {
  gatsby: {
    pathPrefix: '/',
    siteUrl: 'https://help.writone.com',
    gaTrackingId: null,
    trailingSlash: false,
  },
  header: {
    logo: 'https://graphql-engine-cdn.hasura.io/learn-hasura/assets/homepage/brand.svg',
    logoLink: 'https://help.writone.com',
    title: '',
    githubUrl: '',
    helpUrl: '',
    tweetText: '',
    social: `
		    <a href="https://twitter.com/Writone1" target="_blank" rel="noopener">
		      <div class="twitterBtn">
		        <img src='https://storage.googleapis.com/writone-ja-admin/Twitter_Logo_WhiteOnBlue.svg' alt="@Writone1"/>
		      </div>
		    </a>
`,
    links: [{ text: '', link: '' }],
    search: {
      enabled: true,
      indexName: 'all',
      algoliaAppId: 'S1NOLIVU5J',
      algoliaSearchKey: '0f9b040351a8d4602b175656c89f7eac',
      algoliaAdminKey: '3dce9d578b12ed6f362f1b8660a2cd67',
    },
  },
  sidebar: {
    forcedNavOrder: [
      '/introduction', // add trailing slash if enabled above
      '/codeblock',
    ],
    collapsedNav: [
      '/codeblock', // add trailing slash if enabled above
    ],
    links: [{ text: 'Writone', link: 'https://www.writone.com' }],
    frontline: false,
    ignoreIndex: true,
  },
  siteMetadata: {
    title: 'Writoneヘルプセンター　| ライトーン',
    description:
      'Writone（ライトーン）のヘルプセンターです。Writoneのことや操作方法など、知りたいことがあるときはこちらをご利用ください。',
    ogImage: null,
    docsLocation: '',
    favicon: 'https://storage.googleapis.com/writone-ja-admin/logo-favicon.png',
  },
  pwa: {
    enabled: false, // disabling this will also remove the existing service worker.
    manifest: {
      name: 'Gatsby Gitbook Starter',
      short_name: 'GitbookStarter',
      start_url: '/',
      background_color: '#6b37bf',
      theme_color: '#6b37bf',
      display: 'standalone',
      crossOrigin: 'use-credentials',
      icons: [
        {
          src: 'src/pwa-512.png',
          sizes: `512x512`,
          type: `image/png`,
        },
      ],
    },
  },
};

module.exports = config;
